<template>
  <div v-if="visiblePartnership">
    <div>
      <partnershipEdit
        ref="partnershipEdit"
        v-on:partnershipSaved="updateState"
      />
      <div class="float-right">
        <b-btn
          :size="'sm'"
          :variant="'link'"
          @click="onShowEditPartnershipDialog"
          >Edit</b-btn
        >
        &nbsp;
        <b-btn :size="'sm'" :variant="'link'" v-b-modal.confirmDelete
          >Delete Partnership
        </b-btn>

        <b-modal
          id="confirmDelete"
          size="sm"
          ok-variant="danger"
          title="Confirm Delete"
          @ok="onDeletePartnership"
        >
          <p>Do you really want to delete this partnership?</p>
        </b-modal>
      </div>
    </div>

    <h6>Information</h6>
    <ul>
      <li>Partner: {{ visiblePartnership.partner.name }}</li>
      <li v-if="visiblePartnership.name">
        Name: {{ visiblePartnership.name }}
      </li>
      <li v-if="inboundEmail">
        Inbound Email: <span class="text-info">{{ inboundEmail }}</span>
      </li>
    </ul>

    <hr />

    <h6>Harvests</h6>
    <b-list-group>
      <b-list-group-item
        v-for="harvest in harvests"
        v-bind:data="harvest"
        v-bind:key="harvest.identifier"
        class="d-flex justify-content-between align-items-center"
      >
        <router-link
          tag="li"
          :to="{
            path:
              '/accounts/' +
              visiblePartnership.account.identifier +
              '/partnerships/' +
              visiblePartnership.identifier +
              '/harvests/' +
              harvest.identifier,
          }"
        >
          <a> [{{ harvest.year }}] {{ harvest.month | (month - name) }} </a>
        </router-link>
        <b-badge pill v-bind:variant="stateBadgeVariant(harvest)">
          {{ stateBadgeText(harvest) }}
        </b-badge>
      </b-list-group-item>
    </b-list-group>
    &nbsp;
    <div>
      <b-btn
        class="float-right"
        :size="'sm'"
        :variant="'primary'"
        @click="onEnsurePastMonthHarvest"
        >Create New Harvest
      </b-btn>
    </div>
  </div>
</template>

<script lang="ts">
import PartnershipEdit from '@/components/partnershipEdit.vue';
import { Harvest } from '@/model/harvest';
import { HarvestService } from '@/services/harvest.service';
import { SharedStateService } from '@/services/shared-state.service';
import firebase from 'firebase/app';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    PartnershipEdit,
  },
})
export default class PartnershipDetail extends Mixins(
  SharedStateService,
  HarvestService
) {
  public harvests: Harvest[] = [];
  public inboundEmail: string | null = null;

  public constructor() {
    super();
  }

  public async mounted(): Promise<void> {
    await Promise.all([this.fetchHarvests(), this.fetchInboundEmail()]);
  }

  public async fetchHarvests(): Promise<void> {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user != null && user.emailVerified) {
        this.harvests = await this.getHarvests(
          this.visiblePartnershipIdentifier()
        );
        this.sortHarvests(this.harvests);
      }
    });
  }

  public async fetchInboundEmail(): Promise<void> {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user != null && user.emailVerified) {
        this.inboundEmail = await this.getPartnershipInboundEmail(
          this.visiblePartnershipIdentifier()
        );
      }
    });
  }

  public onShowEditPartnershipDialog(): void {
    const editor = this.getPartnershipEdit();
    editor.show();
    editor.setPartnership(this.visiblePartnership!);
  }

  public async onDeletePartnership(): Promise<void> {
    await this.deletePartnership(this.visiblePartnershipIdentifier());
    this.$router.push(`/accounts/${this.visibleAccountIdentifier()}`);
  }

  public async onEnsurePastMonthHarvest(): Promise<void> {
    await this.ensurePastMonthHarvest(this.visiblePartnershipIdentifier());
    await this.fetchHarvests();
  }

  public updateState(): void {
    this.updateSharedState();
  }

  private getPartnershipEdit(): PartnershipEdit {
    return this.$refs.partnershipEdit as PartnershipEdit;
  }
}
</script>
