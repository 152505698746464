import { Account } from '@/model/account';
import { Harvest } from '@/model/harvest';
import { Partnership } from '@/model/partnership';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// I was taught at school that global state was bad, but here we are.
export interface PretallySharedState {
  visibleAccount: Account | null;
  visiblePartnership: Partnership | null;
  visibleHarvest: Harvest | null;
}

// the global shared state
const store = new Vuex.Store<PretallySharedState>({
  state: {
    visibleAccount: null,
    visiblePartnership: null,
    visibleHarvest: null,
  },
  mutations: {
    setVisibleAccount(state, account: Account) {
      state.visibleAccount = account;
    },
    setVisiblePartnership(state, partnership: Partnership) {
      state.visiblePartnership = partnership;
    },
    setVisibleHarvest(state, harvest: Harvest) {
      state.visibleHarvest = harvest;
    },
  },
  actions: {
    setVisible(
      { commit },
      changes: {
        account: Account | null;
        partnership: Partnership | null;
        harvest: Harvest | null;
      }
    ) {
      commit('setVisibleAccount', changes.account);
      commit('setVisiblePartnership', changes.partnership);
      commit('setVisibleHarvest', changes.harvest);
    },
  },
});

export default store;
