<template>
  <div>
    <b-modal
      id="new-partnership"
      ref="newPartnershipDialog"
      v-bind:title="title()"
      @ok="saveOk"
    >
      <b-form v-if="partnership">
        <b-form-select
          ref="partnerSelect"
          v-model="selectedPartner"
          :options="partners"
          class="mb-3"
          v-on:change="onPartnerChange"
          :disabled="!creating"
        >
          <template slot="first">
            <!-- this slot appears above the options from 'options' prop -->
            <option :value="null" disabled
              >-- Please select an option --</option
            >
          </template>
        </b-form-select>
        <b-form-input
          v-model="name"
          type="text"
          placeholder="Name (optional)"
        ></b-form-input>
        &nbsp;
        <codemirror v-model="configuration" :options="cmOptions"></codemirror>
        <b-link class="float-right" @click="resetConfToDefault()"
          ><small>Reset to default</small></b-link
        >
        <!-- b-form-textarea id="configuration"
                     v-model="configuration"
                     placeholder="Enter something"
                     :rows="3"
                     :max-rows="6">
      
    </b-form-textarea -->
      </b-form>
    </b-modal>
  </div>
</template>

<script lang="ts">
import {
  bootstrapifyPartnersAsSelectOptions,
  findBootstrapifiedPartner,
} from '@/bootstrap-tools';
import { Partner } from '@/model/partner';
import { Partnership } from '@/model/partnership';
import { PartnerService } from '@/services/partner.service';
import { PartnershipService } from '@/services/partnership.service';
import 'codemirror/addon/display/autorefresh.js';
import 'codemirror/mode/toml/toml.js';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class PartnershipEdit extends Mixins(
  PartnershipService,
  PartnerService
) {
  public cmOptions: any = {
    tabSize: 4,
    mode: 'text/x-toml',
    autoRefresh: true,
    lineNumbers: true,
    line: true,
  };

  public selectedPartner: Partner | null = null;
  public partners: any[] = [];
  public configuration = '';
  public name = '';

  public partnership: Partnership | null = null;

  public show(): void {
    this.getDialog().show();
  }

  public async mounted(): Promise<void> {
    this.partners = bootstrapifyPartnersAsSelectOptions(
      await this.getAllPartners()
    );
  }

  public get creating(): boolean {
    return !(this.partnership && this.partnership.identifier);
  }

  public onPartnerChange(partner: Partner): void {
    this.configuration = partner.configurationTemplate;
  }

  public setPartnership(partnership: Partnership) {
    this.partnership = partnership;
    this.configuration = partnership.configuration;
    this.name = partnership.name;
    // set the selected partner, finding out from the partnership partner, if any
    if (partnership.partner) {
      const p = findBootstrapifiedPartner(
        this.partners,
        partnership.partner.identifier
      );
      this.selectedPartner = p;
    }
    this.$forceUpdate();
  }

  public resetConfToDefault(): void {
    if (this.selectedPartner) {
      this.configuration = this.selectedPartner.configurationTemplate;
    }
  }

  public saveOk(event: any): void {
    event.preventDefault();
    if (!this.selectedPartner) {
      alert('Please enter a partner');
    } else {
      this.handleSave();
    }
  }

  public async handleSave() {
    if (this.isNew()) {
      await this.createPartnership({
        configuration: this.configuration,
        name: this.name,
        partnerIdentifier: (this.selectedPartner as Partner).identifier,
        accountIdentifier: (this.partnership as Partnership).account
          .identifier!,
      });
    } else {
      await this.updatePartnership({
        identifier: (this.partnership as Partnership).identifier as string,
        configuration: this.configuration,
        name: this.name,
      });
    }
    this.getDialog().hide();
  }

  public title(): string {
    return this.isNew() ? 'New Partnership' : 'Edit Partnership';
  }

  private getDialog() {
    return this.$refs.newPartnershipDialog as any;
  }

  private isNew(): boolean {
    return !this.partnership || !(this.partnership as Partnership).identifier;
  }
}
</script>
