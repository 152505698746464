import AccountDetail from '@/components/accountDetail.vue';
import AccountsList from '@/components/accountsList.vue';
import Auth from '@/components/auth.vue';
import HarvestDetail from '@/components/harvestDetail.vue';
import PartnershipDetail from '@/components/partnershipDetail.vue';
import Verify from '@/components/verify.vue';
import { Account } from '@/model/account';
import { Partnership } from '@/model/partnership';
import Home from '@/views/Home.vue';
import moment from 'moment';
import Vue from 'vue';
import Router from 'vue-router';
import { Harvest } from './model/harvest';
import { PretallySharedState } from './sharedState';

Vue.use(Router);

export interface BreadCrumbItem {
  label?: string;
  link?: string;
  computedLabel?: (state: PretallySharedState) => string;
  computedLink?: (state: PretallySharedState) => string;
}

function partnershipBreadcrumbLabel(partnership: Partnership): string {
  if (partnership) {
    let label = partnership.partner.name;
    if (partnership.partner.name) {
      label += ' - ' + partnership.name;
    }
    return label;
  } else {
    return '';
  }
}

function harvestBreadcrumbLabel(harvest: Harvest): string {
  if (harvest) {
    return (
      harvest.year +
      ' ' +
      moment()
        .month(harvest.month)
        .format('MMMM')
    );
  } else {
    return '';
  }
}

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
        ],
      },
    },
    {
      path: '/verify',
      name: 'verify',
      component: Verify,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
        ],
      },
    },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
        ],
      },
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: AccountsList,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
          {
            label: 'Accounts',
            link: '/accounts',
          },
        ],
      },
    },
    {
      path: '/accounts/:accountIdentifier',
      name: 'account',
      component: AccountDetail,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
          {
            computedLabel: (state: PretallySharedState) =>
              state.visibleAccount!.name,
            computedLink: (state: PretallySharedState) =>
              `/accounts/${state.visibleAccount!.identifier}`,
          },
        ],
      },
    },
    {
      path: '/accounts/:accountIdentifier/partnerships/:partnershipIdentifier',
      name: 'partnership',
      component: PartnershipDetail,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
          {
            computedLabel: (state: PretallySharedState) =>
              state.visibleAccount!.name,
            computedLink: (state: PretallySharedState) =>
              `/accounts/${state.visibleAccount!.identifier}`,
          },
          {
            computedLabel: (state: PretallySharedState) =>
              partnershipBreadcrumbLabel(state.visiblePartnership!),
            computedLink: (state: PretallySharedState) =>
              `/accounts/${state.visibleAccount!.identifier}` +
              `/partnerships/${state.visiblePartnership!.identifier}`,
          },
        ],
      },
    },
    {
      path:
        '/accounts/:accountIdentifier/partnerships/:partnershipIdentifier/harvests/:harvestIdentifier',
      name: 'harvest',
      component: HarvestDetail,
      meta: {
        breadcrumb: [
          {
            label: 'Home',
            link: '/',
          },
          {
            computedLabel: (state: PretallySharedState) =>
              state.visibleAccount!.name,
            computedLink: (state: PretallySharedState) =>
              `/accounts/${state.visibleAccount!.identifier}`,
          },
          {
            computedLabel: (state: PretallySharedState) =>
              partnershipBreadcrumbLabel(state.visiblePartnership!),
            computedLink: (state: PretallySharedState) =>
              `/accounts/${(state.visibleAccount as Account).identifier}` +
              `/partnerships/${state.visiblePartnership!.identifier}`,
          },
          {
            computedLabel: (state: PretallySharedState) =>
              harvestBreadcrumbLabel(state.visibleHarvest!),
            computedLink: (state: PretallySharedState) =>
              `/accounts/${(state.visibleAccount as Account).identifier}` +
              `/partnerships/${state.visiblePartnership!.identifier}` +
              `/harvests/${state.visibleHarvest!.identifier}`,
          },
        ],
      },
    },
  ],
});

// axios.get < Account[] >
export default router;
