<template>
  <div>
    <div>
      A confirmation email has been send. Go to the
      <a href="/">main page</a> once confirmed.
    </div>
    <div>
      <b-btn
        class="float-right"
        :size="'sm'"
        :variant="'primary'"
        @click="resendEmail"
        >Resend confirmation email</b-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import firebase from 'firebase/app';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Verify extends Vue {
  public async resendEmail(): Promise<void> {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      await currentUser.sendEmailVerification();
      Vue.toasted.show('Email sent!');
    } else {
      console.log('Pushing auth here');
      this.$router.push('/auth').catch(() => {
        console.log('failed to navigate to auth');
      });
    }
  }
}
</script>
