import { Vue } from 'vue-property-decorator';

// why import moment from 'moment' instead of import * as moment?
// no idea, seems to be a webpack problem.
import moment from 'moment';

function monthName(value: number): string {
  return moment().month(value).format('MMMM');
}

function dateFormat(value: string): string {
  return value
    ? moment(String(value)).format('YYYY/MM/DD hh:mm:ss')
    : '0000/00/00 00:00:00';
}

// Declaring the global filters
Vue.filter('month-name', monthName);
Vue.filter('format-date', dateFormat);
