import { Partner } from '@/model/partner';
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class PartnerService extends Vue {
  public async getAllPartners(): Promise<Partner[]> {
    const response = await axios.get<Partner[]>('/partners');
    return response.data;
  }
}
