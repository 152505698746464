import { Account } from '@/model/account';
import { Harvest } from '@/model/harvest';
import { Partnership } from '@/model/partnership';
import { Component, Mixins } from 'vue-property-decorator';
import { AccountService } from './account.service';
import { HarvestService } from './harvest.service';
import { PartnershipService } from './partnership.service';

@Component
export class SharedStateService extends Mixins(
  AccountService,
  PartnershipService,
  HarvestService
) {
  public async updateSharedState(): Promise<void> {
    const accountIdentifier = this.visibleAccountIdentifier();
    const partnershipIdentifier = this.visiblePartnershipIdentifier();
    const harvestIdentifier = this.visibleHarvestIdentifier();

    let account: Account | null = null;
    let partnership: Partnership | null = null;
    let harvest: Harvest | null = null;

    if (accountIdentifier) {
      account = await this.getAccount(accountIdentifier);
    }

    if (partnershipIdentifier) {
      partnership = await this.getPartnership(partnershipIdentifier);
    }

    if (harvestIdentifier) {
      harvest = await this.getHarvest(harvestIdentifier);
    }

    this.$store.dispatch('setVisible', {
      account,
      partnership,
      harvest,
    });
  }
}
