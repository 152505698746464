<template>
  <div v-if="visibleHarvest && visiblePartnership">
    <h6>Information</h6>
    <ul>
      <li>
        Status:
        <b-badge pill v-bind:variant="stateBadgeVariant(visibleHarvest)">{{
          stateBadgeText(visibleHarvest)
        }}</b-badge>
      </li>
      <li v-if="visibleHarvest.jobCreatedAt">
        Job started at: {{ visibleHarvest.jobCreatedAt | (format - date) }}
      </li>
      <!--li v-if="visibleHarvest.jobFinishedAt">Job finished at: {{
    visibleHarvest.jobFinishedAt | format-date }} <b-btn class="float-right"
                                                         :size="'sm'"
                                                         :variant="'info'"
                                                         @click="fetchLog">See log
</b-btn></li-->
    </ul>
    <hr />
    <div v-if="isFetchAPossibility()" class="mb-5">
      <b-btn
        class="float-right"
        :disabled="!isFetchAllowed()"
        :size="'sm'"
        :variant="'primary'"
        @click="fetchReport"
        >{{
          visibleHarvest.state !== 'new' ? 'Force Fetch Report' : 'Fetch Report'
        }}
      </b-btn>
    </div>

    <div class="row border-0 py-0" v-if="blobGroups && blobGroups.length > 0">
      <div class="col-12">
        <h6>Trace</h6>
        <div
          class="row"
          v-for="(blobGroup, blobGroupIndex) in blobGroups"
          :key="blobGroupIndex"
        >
          <div class="col-12" v-if="getLogBlobFromGroup(blobGroup)">
            <b-list-group class="border-2 py-2">
              <b-list-group-item class="small border-0 py-0">
                {{ getLogBlobFromGroup(blobGroup).createdAt | (format - date) }}
                [<a
                  href="#"
                  @click.prevent="fetchLog(getLogBlobFromGroup(blobGroup))"
                  >See Log</a
                >]
                <span
                  v-for="(imageBlob, imageIndex) in getImageBlobsFromGroup(
                    blobGroup
                  )"
                >
                  [<a href="#" @click.prevent="fetchImage(imageBlob)"
                    >See {{ imageBlob.name }}</a
                  >]
                </span>
              </b-list-group-item>

              <b-list-group-item
                v-for="fileBlob in getOtherFilesBlobsFromGroup(blobGroup)"
                class="small border-0 py-0"
              >
                → {{ fileBlob.name }} [<a
                  href="#"
                  @click.prevent="downloadToDisk(fileBlob)"
                  >Download</a
                >]
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-modal
        size="lg"
        hide-footer
        id="logDisplay"
        ref="logDisplay"
        v-bind:title="displayedLogBlob && displayedLogBlob.name"
      >
        <pre>{{ displayedLogContent }}</pre>
      </b-modal>
    </div>

    <div>
      <b-modal
        size="lg"
        hide-header
        hide-header-close
        hide-footer
        id="imageDisplay"
        ref="imageDisplay"
        v-bind:title="displayedImageBlob && displayedImageBlob.name"
      >
        <img v-bind:src="displayedImageContent" />
      </b-modal>
    </div>
  </div>
</template>

<script lang="ts">
import { PretallyBlob } from '@/model/blob';
import { Harvest, HarvestState } from '@/model/harvest';
import { BlobService } from '@/services/blob.service';
import { HarvestService } from '@/services/harvest.service';
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator';
import PartnershipDetail from './partnershipDetail.vue';

@Component({})
export default class HarvestDetail extends Mixins(
  HarvestService,
  PartnershipDetail,
  BlobService
) {
  public blobGroups: PretallyBlob[][] = [];
  public displayedLogBlob: PretallyBlob | null = null;
  public displayedLogContent: string = '';

  public displayedImageBlob: PretallyBlob | null = null;
  public displayedImageContent: string = 'data:image/png;base64,';

  public constructor() {
    super();
  }

  public async mounted(): Promise<void> {}

  // Return whether fetch is a possibility for this harvest, for example
  // pushs will be never be able to force a fetch
  public isFetchAPossibility(): boolean {
    return (
      this.visiblePartnership != null &&
      this.visiblePartnership.partner.fetchType === 'pull'
    );
  }

  // Return whether fetch is a possibility in the currenct circumstances
  public isFetchAllowed(): boolean {
    // allow to force refetch after 2 minutes

    return (
      !!this.visibleHarvest &&
      (this.visibleHarvest.state !== HarvestState.InProgress ||
        new Date(this.visibleHarvest!.jobCreatedAt!).getTime() <
          new Date().getTime() - 60000)
    );
  }

  public async fetchReport() {
    await this.fetchReportForHarvest(this.visibleHarvestIdentifier());
    // set this artificially instead of a roundtrip to the backend...
    this.visibleHarvest!.state = HarvestState.InProgress;
    this.visibleHarvest!.jobCreatedAt = new Date().toUTCString();
    this.visibleHarvest!.jobFinishedAt = undefined;
    Vue.toasted.show('Fetch started!');
  }

  public async fetchLog(blob: PretallyBlob) {
    // We support the old blob log (name: 'log') and the new job log (name: 'job-log-id')
    this.displayedLogBlob = blob;

    if (blob.name === 'log') {
      this.displayedLogContent =
        (await this.getBlobContent(blob.identifier)).toString('utf8') || '';
      (this.$refs.logDisplay as any).show();
    } else {
      const url = await this.getJobLogViewer(blob.identifier);
      window.open(url, this.displayedLogBlob.identifier);
    }
  }

  public async fetchImage(blob: PretallyBlob) {
    // this.log = (await this.getBlobContent(id)).toString("utf8") || "";
    this.displayedImageBlob = blob;
    const base64encoded = await this.getBlobImageContent(blob.identifier);
    const imgSrc = `data:${blob.mimeType};base64,${base64encoded}`;
    this.displayedImageContent = imgSrc;
    (this.$refs.imageDisplay as any).show();
  }

  public async downloadToDisk(blob: PretallyBlob) {
    await this.downloadBlobContentToDisk(blob.identifier, blob.name);
  }

  @Watch('visibleHarvest')
  public async onVisibleHarvestLoaded(
    val: Harvest | null,
    oldValue: Harvest | null
  ): Promise<void> {
    if (val) {
      this.blobGroups = this.prepareBlobGroups(
        await this.getBlobs(val.logIds || [])
      );
    }
  }

  public getLogBlobFromGroup(blobs: PretallyBlob[]): PretallyBlob | null {
    for (const blob of blobs) {
      if (blob.mimeType === 'text/plain') {
        if (blob.name === 'job-log-id') {
          return blob;
        }
        if (blob.name === 'log') {
          return blob;
        }
      }
    }
    return null;
  }

  public getImageBlobsFromGroup(blobs: PretallyBlob[]): PretallyBlob[] {
    return blobs.filter(b => b.mimeType === 'image/png');
  }

  public getOtherFilesBlobsFromGroup(blobs: PretallyBlob[]): PretallyBlob[] {
    return blobs.filter(b => b.mimeType === 'application/octet-stream');
  }

  private prepareBlobGroups(blobs: PretallyBlob[]): PretallyBlob[][] {
    const groupsDictionary: any = {};
    const groups: PretallyBlob[][] = [];
    for (const blob of blobs) {
      groupsDictionary[blob.groupId] = groupsDictionary[blob.groupId] || [];
      groupsDictionary[blob.groupId].push(blob);
    }
    for (const groupId in groupsDictionary) {
      if (groupsDictionary.hasOwnProperty(groupId)) {
        const group = groupsDictionary[groupId];
        group.sort(
          (b1: PretallyBlob, b2: PretallyBlob) =>
            new Date(b2.createdAt).getTime() - new Date(b1.createdAt).getTime()
        );
        groups.push(group);
      }
    }
    groups.sort(
      (g1: PretallyBlob[], g2: PretallyBlob[]) =>
        new Date(g2[0].createdAt).getTime() -
        new Date(g1[0].createdAt).getTime()
    );
    return groups;
  }
}
</script>
