import { library } from '@fortawesome/fontawesome-svg-core';
import { faAt, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import axios from 'axios';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
// require styles
import 'codemirror/lib/codemirror.css';
import firebase from 'firebase/app';
import Vue from 'vue';
import VueCodemirror from 'vue-codemirror';
import Toasted from 'vue-toasted';
import Vue2Filters from 'vue2-filters';
import App from './App.vue';
import { Parameters } from './parameters';
import router from './router';
import store from './sharedState';

// USES
Vue.use(VueCodemirror);
Vue.use(Vue2Filters);
Vue.use(Toasted, {
  duration: 2000,
  type: 'info',
  theme: 'primary',
});
Vue.use(BootstrapVue);

// FA
library.add(faCoffee);
library.add(faAt);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

// Create a toast error for all axios calls
axios.interceptors.response.use(
  response => response,
  error => {
    const hasMessage = error.response && error.response.data;
    const errmsg = hasMessage
      ? error.response.data.message
      : 'API connection error';
    Vue.toasted.show(errmsg, { type: 'error' });
    return Promise.reject(error); // this is the important part
  }
);

axios.defaults.baseURL = Parameters.API_ROOT_URL;

// Send the bearer token to the API:
axios.interceptors.request.use(async config => {
  const user = firebase.auth().currentUser;
  if (user) {
    const idToken = await user.getIdToken(/* forceRefresh */ true);
    config.headers.common.Authorization = `bearer ${idToken}`;
  }
  return config;
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
