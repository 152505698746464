<template>
  <div v-if="visibleAccount">
    <div>
      <accountEdit ref="accountEdit" v-on:accountSaved="updateState" />
      <div class="float-right">
        <b-btn :size="'sm'" :variant="'link'" @click="onShowEditAccountDialog"
          >Edit</b-btn
        >
      </div>
    </div>

    <h6>Information</h6>
    <ul>
      <li>FTP Login: {{ visibleAccount.login }}</li>
      <li v-if="inboundEmail">
        Inbound Email: <span class="text-info">{{ inboundEmail }}</span>
      </li>
    </ul>

    <hr />

    <h6>Partnerships</h6>
    <b-list-group>
      <b-list-group-item
        v-for="partnership in partnerships"
        v-bind:data="partnership"
        v-bind:key="partnership.identifier"
        class="d-flex justify-content-between align-items-center"
      >
        <router-link
          tag="li"
          :to="{
            path:
              '/accounts/' +
              visibleAccount.identifier +
              '/partnerships/' +
              partnership.identifier,
          }"
        >
          <a>
            [{{ partnership.partner.name | capitalize }}] {{ partnership.name }}
            <font-awesome-icon
              v-if="partnership.partner.fetchType === 'push'"
              icon="at"
            />
          </a>
        </router-link>

        <span v-if="partnership.harvests && partnership.harvests.length > 0">
          <b-badge
            pill
            v-bind:variant="stateBadgeVariant(partnership.harvests[0])"
          >
            {{ stateBadgeText(partnership.harvests[0]) }}
          </b-badge>
          <router-link
            :to="{
              path:
                '/accounts/' +
                partnership.account.identifier +
                '/partnerships/' +
                partnership.identifier +
                '/harvests/' +
                partnership.harvests[0].identifier,
            }"
          >
            <small class="ml-3">
              {{ partnership.harvests[0].month + 1 }}/{{
                partnership.harvests[0].year
              }}
              →
            </small>
          </router-link>
        </span>
      </b-list-group-item>
    </b-list-group>

    &nbsp;
    <div>
      <partnershipEdit
        ref="partnershipEdit"
        v-on:partnershipSaved="fetchPartnerships"
      />
      <b-btn
        class="float-right"
        :size="'sm'"
        :variant="'primary'"
        @click="showCreatePartnershipDialog"
        >Create New Partnership</b-btn
      >
    </div>
  </div>
</template>

<script lang="ts">
import AccountEdit from '@/components/accountEdit.vue';
import PartnershipEdit from '@/components/partnershipEdit.vue';
import { Account } from '@/model/account';
import { Partnership } from '@/model/partnership';
import { AccountService } from '@/services/account.service';
import { HarvestService } from '@/services/harvest.service';
import { PartnershipService } from '@/services/partnership.service';
import { SharedStateService } from '@/services/shared-state.service';
import firebase from 'firebase/app';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    PartnershipEdit,
    AccountEdit,
  },
})
export default class AccountDetail extends Mixins(
  AccountService,
  PartnershipService,
  SharedStateService,
  HarvestService
) {
  public partnerships: Partnership[] = [];
  public inboundEmail: string | null = null;

  public constructor() {
    super();
  }

  public async mounted(): Promise<void> {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user != null && user.emailVerified) {
        await Promise.all([this.fetchPartnerships(), this.fetchInboundEmail()]);
      }
    });
  }

  public onShowEditAccountDialog(): void {
    const editor = this.getAccountEdit();
    editor.show();
    editor.setAccount(this.visibleAccount!);
  }

  public async fetchPartnerships(): Promise<void> {
    this.partnerships = await this.accountPartnerships(
      this.visibleAccountIdentifier()
    );
    for (const partnership of this.partnerships) {
      this.sortHarvests(partnership.harvests);
    }
  }

  public showCreatePartnershipDialog(): void {
    const editor = this.getPartnershipEdit();
    editor.show();
    editor.setPartnership(this.createNewPartnership());
  }

  public async fetchInboundEmail(): Promise<void> {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user != null && user.emailVerified) {
        this.inboundEmail = await this.getAccountInboundEmail(
          this.visibleAccountIdentifier()
        );
      }
    });
  }

  private getAccountEdit(): AccountEdit {
    return this.$refs.accountEdit as AccountEdit;
  }

  private updateState(): void {
    this.updateSharedState();
  }

  private getPartnershipEdit(): PartnershipEdit {
    return this.$refs.partnershipEdit as PartnershipEdit;
  }

  private createNewPartnership(): Partnership {
    return {
      partner: {
        name: '',
        identifier: '',
        configurationTemplate: '',
        fetchType: '',
      },
      account: this.visibleAccount as Account,
      configuration: '',
      name: '',
      harvests: [],
    };
  }
}
</script>
