<template>
  <div>
    <b-modal
      id="new-account"
      ref="newAccountDialog"
      v-bind:title="title()"
      @ok="saveOk"
    >
      <b-form>
        <b-form-group id="nameGroup" label="Name:" label-for="nameInput">
          <b-form-input
            id="nameInput"
            type="text"
            v-model="name"
            required
            placeholder="Enter name"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group id="loginGroup" label="Ftp Login:" label-for="loginInput">
          <b-form-input
            id="loginInput"
            type="text"
            v-model="login"
            required
            placeholder="Enter login"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          id="passwordGroup"
          label="Ftp Password:"
          label-for="passwordInput"
        >
          <b-form-input
            id="passwordInput"
            type="text"
            v-model="password"
            required
            placeholder="Enter password"
          >
          </b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Account } from '@/model/account';
import { AccountService } from '@/services/account.service';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class AccountEdit extends Mixins(AccountService) {
  public account: Account | null = null;

  public login: string = '';
  public password: string = '';
  public name: string = '';

  public show(): void {
    this.getDialog().show();
  }

  public async handleSave() {
    if (this.isNew()) {
      await this.createAccount({
        name: this.name,
        login: this.login,
        password: this.password,
      });
    } else {
      await this.updateAccount({
        name: this.name,
        identifier: this.account!.identifier!,
        login: this.login,
        password: this.password,
      });
    }
    this.getDialog().hide();
  }

  public setAccount(account: Account) {
    this.account = account;
    this.name = account.name;
    this.login = account.login;
    this.password = account.password;
    this.$forceUpdate();
  }

  public async saveOk(event: any): Promise<void> {
    event.preventDefault();
    if (!this.name || !this.login || !this.password) {
      alert('Please enter all the fields');
    } else {
      this.handleSave();
    }
  }

  public title(): string {
    return this.isNew() ? 'New Account' : 'Edit Account';
  }

  private isNew(): boolean {
    return !this.account || !(this.account as Account).identifier;
  }

  private getDialog() {
    return this.$refs.newAccountDialog as any;
  }
}
</script>
