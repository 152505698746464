import { Harvest } from '@/model/harvest';
import {
  CreatePartnershipDTO,
  Partnership,
  UpdatePartnershipDTO,
} from '@/model/partnership';
import axios from 'axios';
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export class PartnershipService extends Vue {
  @Emit('partnershipSaved')
  public async createPartnership(
    dto: CreatePartnershipDTO
  ): Promise<Partnership> {
    const url = '/partnerships';
    const response = await axios.post<Partnership>(url, dto);
    return response.data;
  }

  @Emit('partnershipSaved')
  public async updatePartnership(
    dto: UpdatePartnershipDTO
  ): Promise<Partnership> {
    const url = '/partnerships';
    const response = await axios.put<Partnership>(url, dto);
    return response.data;
  }

  public async getPartnership(identifier: string): Promise<Partnership> {
    const url = `/partnerships/${identifier}`;
    const response = await axios.get<Partnership>(url);
    return response.data;
  }

  public async accountPartnerships(
    accountIdentifier: string
  ): Promise<Partnership[]> {
    const url = `/accounts/${accountIdentifier}/partnerships`;
    const response = await axios.get<Partnership[]>(url);
    return response.data;
  }

  public async getHarvests(partnershipIdentifier: string): Promise<Harvest[]> {
    const url = `/partnerships/${partnershipIdentifier}/harvests`;
    const response = await axios.get<Harvest[]>(url);
    return response.data;
  }

  public async getPartnershipInboundEmail(
    partnershipIdentifier: string
  ): Promise<string> {
    const url = `/partnerships/${partnershipIdentifier}/inboundemail`;
    const response = await axios.get<string>(url);
    return response.data;
  }

  public async ensurePastMonthHarvest(
    partnershipIdentifier: string
  ): Promise<void> {
    const url = `/partnerships/${partnershipIdentifier}/harvests/ensure-past-month`;
    await axios.post<Harvest[]>(url);
  }

  public async deletePartnership(partnershipIdentifier: string): Promise<void> {
    const url = `/partnerships/${partnershipIdentifier}`;
    await axios.delete(url);
  }

  public visiblePartnershipIdentifier(): string {
    return this.$route.params.partnershipIdentifier;
  }

  // VUEX, GLOBAL STATE
  // We assume there is at most one visible account
  public get visiblePartnership(): Partnership | null {
    return this.$store.state.visiblePartnership;
  }
}
