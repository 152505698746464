import { Account, CreateAccountDTO, UpdateAccountDTO } from '@/model/account';
import axios from 'axios';
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export class AccountService extends Vue {
  public async getAllAccounts(): Promise<Account[]> {
    const url = '/accounts';
    const response = await axios.get<Account[]>(url);
    return response.data;
  }

  @Emit('accountSaved')
  public async createAccount(accountData: CreateAccountDTO): Promise<Account> {
    const url = '/accounts';
    const response = await axios.post<Account>(url, accountData);
    return response.data;
  }

  @Emit('accountSaved')
  public async updateAccount(dto: UpdateAccountDTO): Promise<Account> {
    const url = '/accounts';
    const response = await axios.put<Account>(url, dto);
    return response.data;
  }

  public async getAccount(id: string): Promise<Account> {
    const url = `/accounts/${id}`;
    const response = await axios.get<Account>(url);
    return response.data;
  }

  public async getAccountInboundEmail(
    accountIdentifier: string
  ): Promise<string> {
    const url = `/accounts/${accountIdentifier}/inboundemail`;
    const response = await axios.get<string>(url);
    return response.data;
  }

  public visibleAccountIdentifier(): string {
    return this.$route.params.accountIdentifier;
  }

  // VUEX, GLOBAL STATE
  // We assume there is at most one visible account
  public get visibleAccount(): Account | null {
    return this.$store.state.visibleAccount;
  }
}
