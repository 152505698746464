<template>
  <div id="app">
    <b-navbar toggleable="md" type="light" variant="light">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand href="#">Pretally</b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="user">
            <template slot="button-content">
              <b-img
                rounded="circle"
                blank
                width="30"
                :src="this.user.photoURL"
                height="30"
                blank-color="#777"
                alt="img"
                class="m-1"
                v-if="this.user.photoURL"
              />
              <em>{{ this.user.displayName }}</em>
            </template>
            <b-dropdown-item @click="logOut">Signout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-breadcrumb :items="breadcrumb" />
    <div id="nav">
      <!--router-link to="/about">About</router-link-->
    </div>
    <div class="container">
      <RouterView ref="routerView" />
    </div>
  </div>
</template>

<script lang="ts">
import '@/filters/pretally.filters';
import { Parameters } from '@/parameters';
import { SharedStateService } from '@/services/shared-state.service';
import firebase from 'firebase/app';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import '../node_modules/firebaseui/dist/firebaseui.css';
import { bootstrapifyRouterCrumbs } from './bootstrap-tools';

@Component({
  components: {},
})
export default class App extends Mixins(SharedStateService) {
  public user: firebase.User | null = null;

  public breadcrumb: Array<{ text: string; to: string }> = [];

  public constructor() {
    super();
    firebase.initializeApp(Parameters.FIREBASE_CONFIG);
  }

  public async mounted(): Promise<void> {
    await this.handleAuthenticationChange();
    // set the store, from the path
  }

  @Watch('$route')
  public async onRouteChange(): Promise<void> {
    // update the store from the path
    await this.updateSharedState();
  }

  public logOut() {
    firebase.auth().signOut();
  }

  private updateBreadCrumb() {
    if (this.$route.meta) {
      this.breadcrumb = bootstrapifyRouterCrumbs(
        this.$store.state,
        this.$route.meta.breadcrumb
      );
    }
  }

  private async handleAuthenticationChange() {
    await firebase.auth().onAuthStateChanged(async user => {
      this.user = user;
      if (!this.user) {
        console.log('CALLED HERE');
        this.$router.push('/auth').catch(e => {
          console.log('redundant navigation?');
        });
      } else if (!this.user.emailVerified) {
        this.$router.push('/verify');
      }
      this.$store.subscribeAction({
        after: () => {
          this.updateBreadCrumb();
        },
      });
      await this.updateSharedState();
    });
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
</style>
