import { PretallyBlob } from '@/model/blob';
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class BlobService extends Vue {
  public async getBlobContent(identifier: string): Promise<Buffer> {
    const url = `/blobs/${identifier}/content`;
    const response = await axios.get(url);
    return response.data;
  }

  public async getJobLogViewer(identifier: string): Promise<string> {
    const url = `/blobs/${identifier}/log/viewer`;
    const response = await axios.get(url);
    return response.data;
  }

  public async getBlobImageContent(identifier: string): Promise<string> {
    const url = `/blobs/${identifier}/content`;
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    const base64 = btoa(
      new Uint8Array(response.data).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ''
      )
    );
    return base64;
  }

  public async downloadBlobContentToDisk(
    identifier: string,
    fileName: string
  ): Promise<void> {
    const response = await axios.get(`/blobs/${identifier}/content`, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }

  public async getBlobs(identifiers: string[]): Promise<PretallyBlob[]> {
    const url = `/blobs`;
    const response = await axios.get<PretallyBlob[]>(url, {
      params: { identifiers },
    });
    return response.data;
  }
}
