<template>
  <div>
    <div id="firebaseui-auth-container"></div>
  </div>
</template>

<script lang="ts">
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Auth extends Vue {
  public async mounted(): Promise<void> {
    console.log('mounted AUTH');
    const uiConfig: firebaseui.auth.Config = {
      signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
          if (authResult.additionalUserInfo.isNewUser) {
            authResult.user
              .sendEmailVerification()
              .then(() => {
                this.$router.push('/verify');
              })
              .catch((err: any) => {});
          }
          this.$router.push('/');
          return false;
        },
      },
    };
    const ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
  }
}
</script>
