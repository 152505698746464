<template>
  <div><AccountsList /></div>
</template>

<script lang="ts">
import AccountsList from '@/components/accountsList.vue'; // @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    AccountsList,
  },
})
export default class Home extends Vue {
  public constructor() {
    super();
  }
}
</script>
