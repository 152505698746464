import { Partner } from './model/partner';
import { BreadCrumbItem } from './router';
import { PretallySharedState } from './sharedState';

// from the meta.breadcrumb of each route, produce the list of items expected by
// vue-bootstrap breadcrumb component.
export function bootstrapifyRouterCrumbs(
  state: PretallySharedState,
  crumb: BreadCrumbItem[]
): Array<{ text: string; to: string }> {
  return crumb.map(part => ({
    text: part.computedLabel
      ? part.computedLabel(state)
      : (part.label as string),
    to: part.computedLink ? part.computedLink(state) : (part.link as string),
  }));
}

export function bootstrapifyPartnersAsSelectOptions(
  partners: Partner[]
): any[] {
  return partners.map(partner => ({ value: partner, text: partner.name }));
}

export function findBootstrapifiedPartner(
  partners: Array<{ value: Partner; text: string }>,
  identifier: string
) {
  const option = partners.find(
    partner => partner.value.identifier === identifier
  );
  if (option) {
    return option.value;
  } else {
    return null;
  }
}
