import { render, staticRenderFns } from "./accountsList.vue?vue&type=template&id=6b1ab959&scoped=true"
import script from "./accountsList.vue?vue&type=script&lang=ts"
export * from "./accountsList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b1ab959",
  null
  
)

export default component.exports