import { Partnership } from './partnership';

export enum HarvestState {
  New = 'new',
  InProgress = 'in_progress',
  Failed = 'failed',
  Success = 'success',
}

export interface Harvest {
  identifier: string;

  year: number;

  month: number;

  uploadedFiles: number;

  createdAt: string;

  jobCreatedAt?: string;

  jobFinishedAt?: string;

  logIds?: string[];

  state: HarvestState;

  partnership: Partnership;
}
