import { Harvest, HarvestState } from '@/model/harvest';
import axios from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class HarvestService extends Vue {
  public visibleHarvestIdentifier(): string {
    return this.$route.params.harvestIdentifier;
  }

  public async getHarvest(identifier: string): Promise<Harvest> {
    const url = `/harvests/${identifier}`;
    const response = await axios.get<Harvest>(url);
    return response.data;
  }

  public async fetchReportForHarvest(identifier: string): Promise<void> {
    const url = `/harvests/${identifier}/fetchreports`;
    await axios.post(url);
  }

  // VUEX, GLOBAL STATE
  // We assume there is at most one visible account
  public get visibleHarvest(): Harvest | null {
    return this.$store.state.visibleHarvest;
  }

  public sortHarvests(harvests: Harvest[]): void {
    // do nothing if no harvests
    if (!harvests) {
      return;
    }
    function compare(h1: Harvest, h2: Harvest) {
      if (h1.year !== h2.year) {
        return h2.year - h1.year;
      } else {
        return h2.month - h1.month;
      }
    }
    harvests.sort(compare);
  }

  public stateBadgeVariant(harvest: Harvest): string {
    switch (harvest && harvest.state) {
      case HarvestState.Success: {
        if (harvest.uploadedFiles === 0) {
          return 'warning';
        } else {
          return 'success';
        }
      }
      case HarvestState.Failed: {
        return 'danger';
      }
      case HarvestState.InProgress: {
        return 'warning';
      }
      default: {
        return 'light';
      }
    }
  }

  public stateBadgeText(harvest: Harvest): string {
    switch (harvest && harvest.state) {
      case HarvestState.Success: {
        return `${harvest.uploadedFiles} uploaded files`;
      }
      case HarvestState.Failed: {
        return 'ERROR';
      }
      case HarvestState.InProgress: {
        return 'In progress...';
      }
      case HarvestState.New: {
        return 'Waiting';
      }
      default: {
        return harvest.state;
      }
    }
  }
}
