<template>
  <div>
    <h6>Accounts</h6>
    <b-list-group>
      <b-list-group-item
        v-for="account in accounts"
        v-bind:data="account"
        v-bind:key="account.identifier"
      >
        <router-link tag="li" :to="{ path: '/accounts/' + account.identifier }">
          <a>
            {{ account.name | capitalize }}
          </a>
        </router-link>
      </b-list-group-item>
    </b-list-group>
    &nbsp;
    <div>
      <b-btn
        class="float-right"
        :size="'sm'"
        :variant="'primary'"
        @click="showCreateAccountModal"
        >Create New Account</b-btn
      >
      <accountEdit ref="accountEdit" v-on:accountSaved="fetchAccounts" />
    </div>
  </div>
</template>

<script lang="ts">
import { Account } from '@/model/account';
import { AccountService } from '@/services/account.service';
import firebase from 'firebase/app';
import { Component, Mixins } from 'vue-property-decorator';
import AccountEdit from './accountEdit.vue';

@Component({
  components: {
    AccountEdit,
  },
})
export default class AccountsList extends Mixins(AccountService) {
  public accounts: Account[] = [];

  public async mounted(): Promise<void> {
    await firebase.auth().onAuthStateChanged(async user => {
      if (user != null && user.emailVerified) {
        await this.fetchAccounts();
      }
    });
  }

  public async fetchAccounts(): Promise<void> {
    this.accounts = await this.getAllAccounts();
  }

  public showCreateAccountModal(): void {
    const editor = this.$refs.accountEdit as AccountEdit;
    editor.show();
    editor.setAccount(this.createNewAccount());
  }

  private createNewAccount(): Account {
    return {
      name: '',
      login: '',
      password: '',
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
